// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]6.1-10540(78ff7e85d6)-C09/09/2024-15:59:35-B09/09/2024-16:30:00' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]6.1-10540(78ff7e85d6)-C09/09/2024-15:59:35-B09/09/2024-16:30:00",
  branch: "master",
  latestTag: "6.1",
  revCount: "10540",
  shortHash: "78ff7e85d6",
  longHash: "78ff7e85d66539e79d24f0cd91aa87037bf855b3",
  dateCommit: "09/09/2024-15:59:35",
  dateBuild: "09/09/2024-16:30:00",
  buildType: "Ansible",
  } ;
